body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h6 {
  font-size: 0.8rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span.log {
  margin-bottom: 8px;
}

span.log.warning {
  color: yellow
}

span.log.error {
  color: red
}

span.log.info {
  color: white
}

button.fullscreen {
  background-color: #222;
  color: #7988bb;
  margin: 15px
}

.CrawlerAppRight.fullscreen {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background-color: blueviolet;
  width: auto;

  .stdOutLogElement {
    white-space: pre-line;
    line-height: 1.4;
    background-color: black;
    color: white;
    margin: 2px;
    padding-top: 3px;
    padding-bottom: 1px;
    padding-left: 3px;
    font-size: 13px;
  }
}

navbar {
  border-bottom: 1px solid #444;
}

.login {
  text-align: center;

  button {
    padding: 10px 100px;
    border-radius: 5px;
    background-color: #7988bb;
    color: #FFF;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
}

.environment-label {
  margin-right: 10px;
  font-weight: bold;
  background: rgb(146, 56, 56) !important;
  padding: 5px;
  border-radius: 5px;
}



.version-label {
  margin-right: 10px;
  font-weight: bold;
  background: rgb(75, 44, 151) !important;
  padding: 5px;
  border-radius: 5px;
}

.clientSelect,
.projectPathSelect {
  min-width: 250px;
}

.portSelect {
  min-width: 100px;
}

.refreshClients {
  margin: 4px;
}

.projectPathSelect {
  margin-right: 10px;
}

.selectCommandType {
  margin-top: 5px;

  div[class*="-menu"] {
    top: auto;
    bottom: 100%;
  }
}

.reactSelect {
  div[class*="-control"] {
    background-color: transparent;
    color: #CCC;
  }

  div[class*="-menu"] {
    background-color: #222;
  }

  div[class*="-singleValue"] {
    color: #FFF;
  }

  div[class*="-Input"] {
    color: #FFF !important;
  }

  div[class*="-menu"]>div>div {
    background-color: transparent;
  }

  div[class*="-menu"]>div>div>div {
    background-color: transparent !important;

    &:hover {
      background-color: #555 !important;
    }
  }
}


.filterStatus {
  min-width: 100px;
  padding-bottom: 0px;
  position: relative;

  >div {
    border-bottom: 0 !important;
  }
}

.CompWrapper {
  margin-top: 20px;
  position: relative;

  & .ButtonBar {
    position: absolute;
    right: 0;
    top: 0;

    & button {
      width: 32px;
      margin-right: 3px;
    }
  }

  &>h6 {
    cursor: pointer;
    user-select: none;
  }
}

/*
.CrawlerTree, .CommandsTree {
  & .rc-tree {
    max-height: 160px;
    height: 160px;
    overflow: auto;
  }
}
*/

.CrawlerTree .crawlerKey {
  max-width: 45%;
  overflow-x: hidden;
}

.stdOutLog {
  height: calc(100vh - 200px);
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 85%;
}

.stdOutLogElement {
  border-bottom: 1px solid #444;
  white-space: pre-line;
}

.hasLogs {
  background: rgb(146, 56, 56) !important;
  border-radius: 5px 5px 0 0;
}

.dataGrid1 {
  height: 100% !important;
  padding: 0;
  border-right: 0px solid transparent !important;
  border-bottom: 0px solid transparent !important;
  border-left: 0px solid transparent !important;
  background-color: transparent !important;
}

.dataGrid1 div[role="row"] {
  border-left: 1px solid #444 !important;
  border-right: 1px solid #444 !important;
}

div[role="gridcell"] {
  user-select: text !important;
}

/* Without title */
$commandsWithoutTitle: "if", "fromCrawlers", "jsscript", "pyscript", "while", "click", "deleteXpath", "wait", "loop", "pipeAll", "sitemap", "yield", "output", "folder", "filter";

@each $command in $commandsWithoutTitle {
  .#{$command} {
    & .rc-tree-title {
      visibility: hidden;
      font-size: 0;

      &::before {
        content: "#{$command}";
        text-transform: capitalize;
        visibility: visible;
        font-size: 13px;
      }
    }
  }
}

/* With title */
$commandsWithTitle: "extract", "extractTable", "extractTableMulti", "mapString", "extractFromString", "list", "load", "pipe", "getLang", "valueToField", "valueToFieldStats", "deleteField", "setValue", "textProcessor", "storeThead";

@each $command in $commandsWithTitle {
  .#{$command} .rc-tree-title::before {
    content: "#{$command}: ";
    text-transform: capitalize;
  }
}

/* -------- */

.workspaceMain .spinner-border {
  margin-right: 5px;
}


.workspaceTabPanel>.row {
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh - 130px);
  max-height: calc(100vh - 130px);
}

.workspaceWithSubTabs .workspaceTabPanel>.row {
  height: calc(100vh - 145px);
}



.workspaceTabPanel .react-tabs__tab-list .resultHeader {
  display: inline-block;
  position: relative;
  bottom: -7px;
  left: 50px
}

.workspaceTabPanel .react-tabs__tab-list .resultHeader pre {
  margin: 0
}

.workspaceTop,
.workspaceBottom {
  /* weired bug */
  /* https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336 */
  min-height: 0;
}

.workspaceTop {
  flex: 1;
  overflow: auto;
}

.workspaceBottom {
  align-self: flex-end;
  margin-bottom: 5px;
  margin-top: 25px;
  max-height: 25vh;
  overflow-y: auto;
}

.overflowingContentWidgets {
  display: none; //Experimantal (weird overflow-bug)
}

.workspaceTabPanelCrawler button[type="submit"],
.workspaceTabPanelClient button[type="submit"],
.workspaceBottom button,
.ProcConfigTree button[type="submit"] {
  display: none;
}

.Form_BatchJobOverview,
.Form_CompareStatsSnapshotsOverview {
  fieldset {
    display: grid;
    column-gap: 2rem;
  }

  button[type="submit"] {
    display: none;
  }
}




.Form_WorkspaceMatchEcedFull_wrapper {
  position: relative;

  button.loadFilters {
    top: 70px;
    left: 150px;
    position: absolute;
  }
}

.Form_WorkspaceMatchEcedFull {
  //  display: grid;
  //  grid-template-columns: fit-content(90%) 1fr;
  //  column-gap: 2rem;
  margin-bottom: 20px;

  button[type="submit"] {
    top: 70px;
    left: 0;
    position: absolute;
  }



  fieldset {
    display: grid;
    column-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    >div {
      grid-row: 1;
    }

    .column1 {
      grid-column: 1;
    }

    .column2 {
      grid-column: 2;
    }

    .column3 {
      grid-column: 3;
    }

    .column4 {
      grid-column: 4;
    }

    .column5 {
      grid-column: 5;
    }

    .row2 {
      grid-row: 2;
    }


  }
}

.Form_BatchJobConfig {}

.Form_BatchJobOverview fieldset {
  grid-template-columns: 1fr 1fr 1fr;

}

.Form_CompareStatsSnapshotsOverview fieldset {
  grid-template-columns: 1fr 1fr 1fr;
}

.FormStatisticDiffHeader {
  display: flex;
  column-gap: 2rem;
  align-items: flex-end;

  >.field {
    flex: 1
  }

  fieldset {
    display: flex;
    column-gap: 2rem;

    >div {
      flex: 1;
    }
  }
}



.workspaceTopicsCombs {
  .insertItem {
    /*
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    padding-top: 20px;
    padding-bottom: 20px;
    */
    margin-bottom: 10px;
  }

  .FormTopicsNewRule,
  .FormTopicsSettings {
    display: flex;
    align-items: end;
    column-gap: 2px;

    fieldset {
      display: flex;
      column-gap: 2px;
    }
  }

  .FormTopicsSettings fieldset {
    column-gap: 20px;
    margin-top: 5px;

  }

  .FormTopicsRules {
    position: relative;
    column-gap: 2px;

    legend {
      display: none;
    }

    fieldset {
      display: flex;
      column-gap: 2px;
    }

    button.btn-add {
      padding: 0;
      width: 24px;

      &::before {
        content: "+";
      }
    }

    .array-item-add {
      position: absolute;
      z-index: 10;
      top: -20px;
      right: -35px;
      display: inline;
      width: auto;
    }

    .array-item {
      display: flex;
      align-items: end;
      column-gap: 2px;

      .btn-group {
        display: block !important;

        button.array-item-move-up,
        button.array-item-move-down {
          display: none;
        }

        button.array-item-remove {
          padding: 0;
          border-radius: 0.375rem !important;

          &::before {
            content: "x";
          }
        }
      }
    }

    /*
  display: flex;
  column-gap: 2rem;
  align-items: flex-end;

  >.field {
    flex: 1
  }


  */
  }
}


.batchJobOverview div[role="grid"] div [role="gridcell"]:nth-child(8),
.batchJobOverviewItem div[role="grid"] div [role="gridcell"]:nth-child(13),
.batchJobOverviewItem div[role="grid"] div [role="gridcell"]:nth-child(14) {
  background-color: rgb(146, 56, 56);

  &:empty {
    background-color: transparent;
  }
}

.flex_header {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {}

  .content {
    flex-grow: 1;

    &:empty {
      display: none;
    }
  }
}

.batchJobOverview {
  height: 60px !important;
}

.topicGroup {
  height: 400px !important;
}

.topicGroupCategories {
  height: 200px !important;
}

.batchJobTabs {
  padding-top: 10px;
  padding-bottom: 10px;
}


.workspaceBottom>.pipe fieldset,
.workspaceBottom>.pipeAll fieldset {
  display: flex;
}

.ProcConfigTree .disabled1 span {
  text-decoration: line-through;
}

.wordsLibAddMultiple {
  button[type="submit"] {
    display: none;
  }
}

.wordsLibForm {
  & input {
    font-size: 0.8rem;
    padding: 1px;
    margin-bottom: 2px;
  }
}

.wordsLibrary {
  & button.btn-sm {
    font-size: 0.7rem;
    padding: 3px;
    line-height: 1;
  }
}

.wordsLibForm_understand {
  & fieldset {
    display: grid;
    grid-gap: 0px 2px;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;

    &>div {
      &.understandValue {
        grid-column: 1;
        grid-row: 1;
      }

      &.understandValueMatch {
        grid-column: 2;
        grid-row: 1;
      }
    }
  }

  &.Priceperunit {
    & fieldset {
      grid-template-columns: 1fr 1fr;

      #root_valueMatch.form-control {
        padding: 1px !important;
        font-size: 13px;
      }
    }
  }
}

.wordsLibForm_understand.Cluster {
  & fieldset {
    display: grid;
    grid-gap: 0px 2px;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;

    &>div {
      &.understandValue {
        grid-column: 1;
        grid-row: 1;
      }

      &.understandValueMatch {
        grid-column: 1;
        grid-row: 2;
      }

    }

    margin-bottom: 10px;
  }
}

.wordsLibForm_valueToField {
  & fieldset {
    display: grid;
    grid-gap: 0px 2px;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;

    &>div {
      &.value {
        grid-column: 1;
        grid-row: 1;
      }

      &.suffix {
        grid-column: 2;
        grid-row: 1;
      }

      &.fields {
        grid-column: 1 /3;
        grid-row: 2;
      }
    }
  }
}

.workspaceBottom {

  &>.extract,
  .extractTable,
  .extractTableMulti,
  .pipe,
  .pipeAll,
  .textProcessor {
    & fieldset {
      grid-gap: 5px 20px;
      display: grid;
    }
  }

  &>.extract {
    & fieldset {
      grid-template-columns: 3fr 1fr;
      grid-template-rows: min-content min-content min-content 1fr;

      &>div {
        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1/5;
        }

        &:nth-child(2) {
          grid-column: 2;
          grid-row: 1;
        }

        &:nth-child(3) {
          grid-column: 2;
          grid-row: 2;
        }

        &:nth-child(4) {
          grid-column: 2;
          grid-row: 3;
        }
      }
    }
  }

  &>.extractTable,
  &>.extractTableMulti {
    & fieldset {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content min-content min-content 1fr;

      &>div {
        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1;
        }

        &:nth-child(2) {
          grid-column: 1;
          grid-row: 2;
        }

        &:nth-child(3) {
          grid-column: 1;
          grid-row: 3;
        }

        &:nth-child(4) {
          grid-column: 2;
          grid-row: 1;
        }

        &:nth-child(5) {
          grid-column: 2;
          grid-row: 2;
        }

        &:nth-child(6) {
          grid-column: 2;
          grid-row: 3;
        }

        &:nth-child(7) {
          grid-column: 2;
          grid-row: 4;
        }
      }
    }
  }

  &>.pipe,
  &>.pipeAll {
    & fieldset {
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-rows: min-content min-content 1fr;

      &>div {
        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1/5;
        }

        &:nth-child(2) {
          grid-column: 2;
          grid-row: 1;
        }

        &:nth-child(3) {
          grid-column: 3;
          grid-row: 1;
        }
      }
    }
  }

  & .textProcessor {
    & fieldset {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;

      &>div {
        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1;
        }

        &:nth-child(2) {
          grid-column: 2;
          grid-row: 1;
        }
      }
    }
  }
}

.processorDetails {
  grid-template-columns: 3fr 1fr;
  grid-template-rows: min-content 4fr 200px;
  align-items: stretch;
  display: grid;
  height: 100%;

  &>* {
    overflow-y: auto;
  }

  & .elem1-0 {
    grid-row: 1;
    grid-column: 1/2;
  }

  & .elem1-1 {
    grid-row: 1;
    grid-column: 2/3;
    text-align: right;
  }

  & .elem2-0 {
    grid-row: 2;
    grid-column: 1/3;
    overflow-y: auto;
    white-space: pre-line;
  }

  & .elem3-0 {
    grid-row: 3;
    grid-column: 1/3;
  }
}

.extractTableDetails {
  grid-template-columns: 3fr 1fr;
  grid-template-rows: min-content max-content;
  align-items: stretch;
  display: grid;
  height: 100%;

  &>* {
    overflow-y: auto;
  }

  & .elem1-0 {
    grid-row: 1;
    grid-column: 1/2;
  }

  & .elem1-1 {
    grid-row: 1;
    grid-column: 2/3;
    text-align: right;
  }

  & .elem2-0 {
    grid-row: 2;
    grid-column: 1/3;
    overflow-y: auto;
    white-space: pre-line;
  }

  & .elem3-0 {
    grid-row: 3;
    grid-column: 1/3;
  }
}

.errorWorkspace {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;

  & .errorNavi {
    grid-row: 1;
    grid-column: 1/2;
    padding-right: 20px;
  }

  & .errorDetail {
    grid-row: 1;
    grid-column: 2/3;
  }
}

.workspaceNavigation {
  display: none;

  &.react-tabs__tab-panel--selected {
    display: block;
  }
}

.below99>div {
  width: 99%
}

.workspaceTop>.row,
.workspaceRight>.row,
.height100:not(:empty) {
  /* Data Grid needs height */
  height: 100%;

  >div>div[role='grid'] {
    height: 100%;
  }
}

.tabsTopicCombs {
  height: 100%;
  position: relative;

  .workspaceTabPanel.react-tabs__tab-panel--selected {
    height: calc(100% - 30px);
  }


}

.itemsTableConsolePlain {
  white-space: pre;
}

.workspaceLeft,
.workspaceRight {
  max-height: 100%;
  overflow: auto;
}

.workspaceRight {
  white-space: pre;

  &>.react-tabs {
    height: 100%;

    &>div {
      height: calc(100% - 44px);

      &>div {
        height: 100%;

        &>.react-tabs__tab-panel {
          height: 100%;
        }
      }
    }

  }
}

.noAutoHeight {
  height: calc(100vh - 358px);
  max-height: calc(100vh - 358px);
  overflow-y: auto;
  overflow-x: hidden;
}

.itemsTableConsole {
  max-height: 100%;
  overflow-y: auto;
}

.LoopCommand .noAutoHeight {
  height: calc(100vh - 403px);
  max-height: calc(100vh - 403px);
  overflow-y: auto;
  overflow-x: hidden;
}

.filterErrorInfoNoExt {
  margin-bottom: 20px;
}

div {
  &.rowGreen {
    background-color: rgb(118, 153, 123);
  }

  &.rowRed,
  &.status_Errors {
    background-color: rgb(146, 56, 56);
  }

  &.rowOrange,
  &.status_Infos {
    background-color: rgb(146, 100, 56);
  }

  &.rowGray,
  &.status_NoExt {
    background-color: rgb(155, 155, 155);
  }

  &.rowDarkGray {
    background-color: rgb(39, 39, 39);
  }
}



.xpathItem {
  margin-right: 2px;
  display: inline-block;
  text-align: center;

  & .btn i {
    pointer-events: none;
  }
}

.btn-node {
  padding: 0;
  font-size: 0.8rem;
}

.wordsLibrary {

  legend,
  /*:not(.checkbox)>label,*/
  .form-group>label,
  button[type="submit"] {
    display: none;
  }
}

.summery>.row {
  height: 430px;
  zoom: 0.85;
}

.summery .dataGrid1 {
  overflow-x: hidden !important;
}

.dataGridWrapper {
  position: relative;

  & .btn-download-xlsx,
  .btn-download-json {
    position: absolute;
    right: 40px;
    width: 63px;
    bottom: 10px;
    height: 30px;
    z-index: 1;
    display: none;
  }

  .btn-download-json {
    right: 110px;
  }

  &:hover {

    .btn-download-xlsx,
    .btn-download-json {
      display: block;
    }
  }
}

.wordslibFilter {
  color: #777;
  margin-top: 2px;
  margin-bottom: 4px;
}

.wordsLibContainer {
  height: calc(100vh - 790px);
  overflow-y: auto;
}


.itemsMaxInput {
  width: calc(100% - 50px);
  display: inline-block;
  margin-left: 25px;
}

.notLoading {
  width: 21px;
  display: inline-block;
}

#root_deactUnderstandSets>.checkbox {
  display: inline-block;
  width: 150px;
}

/*
@at-root {
  .react-contextmenu-wrapper {
    display: contents;
  }

  .react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    color: #373a3c;
    font-size: 16px;
    margin-block-start: 2px;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    min-inline-size: 160px;
    outline: none;
    opacity: 0;
    padding-block: 5px;
    padding-inline: 0;
    pointer-events: none;
    text-align: start;
    transition: opacity 250ms ease !important;
  }

  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
  }

  .react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding-block: 3px;
    padding-inline: 20px;
    text-align: inherit;
    white-space: nowrap;
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: #878a8c;
  }

  .react-contextmenu-item--divider {
    border-block-end: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
    margin-block-end: 3px;
    padding-block: 2px;
    padding-inline: 0;
  }

  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  .react-contextmenu-item.react-contextmenu-submenu>.react-contextmenu-item::after {
    content: '▶';
    display: inline-block;
    position: absolute;
    inset-inline-end: 7px;
  }

  .example-multiple-targets::after {
    content: attr(data-count);
    display: block;
  }
}
*/

.childrenContainer.close {
  display: none;
}

.treeSelect .row.node {
  &>.colMenu {
    cursor: row-resize;
  }

  & .btn.btn-sm {
    padding: 1px 5px;
  }
}

.openDeep a {
  margin-right: 3px;
  border: 1px solid rgb(66, 66, 66);
  padding: 2px 3px;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: #4d4d4d;
  }
}

.treeSelect a {
  color: #FFF;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.treeSelect .row {
  & i {
    font-size: 17px;
    margin-right: 3px;
  }

  &.leaf {
    &.status_true i {
      color: rgb(25, 151, 0);
    }

    &.status_false i {
      color: rgb(177, 0, 0);
    }

    &.status_null i {
      color: rgb(131, 131, 131);
    }
  }
}

.treeSelect .row.node {
  border: 2px solid transparent;

  &:hover {
    border: 2px solid rgb(94, 0, 90);

    &+div.childrenContainer {
      box-shadow: 0px 0px 2px #ffffff;
    }
  }
}

.statiRadioGroup>input {
  zoom: 1.2;
  margin-right: 3px;
  margin-top: 4px;

  &:last-child {
    margin-right: 0px;
  }
}

input.itemsTableFilter {
  line-height: 20px;
  width: calc(100% - 25px);
  display: inline-block;
}

input.itemsTableGrouped {
  display: inline-block;
  line-height: 20px;
  width: 20px;
  margin-left: 5px;
}

.rdg-header-row>.rdg-cell {
  line-height: 25px;
}

.contextMenu {
  position: absolute;
  width: 1000px;
  padding: 15px;
  border: 1px solid #FFF;
  border-radius: 5px;
  background-color: var(--bs-body-bg);
  z-index: 1;

  .nav-pills .nav-link.nav-link-grey {
    background-color: var(--bs-secondary);
    color: #FFF;

    &.active1 {
      background-color: var(--bs-primary);
    }

    &.disabled {
      background-color: var(--bs-grey);
    }
  }
}


.ecedFullSelect .react-select-list {
  background-color: var(--bs-body-bg);
  border: 1px solid #FFF;
  border-radius: 0px 0px 5px 5px;
  margin: 0px -16px;

  .desc {
    font-size: 0.7rem;
  }

  .react-select-options-ecedFull {
    padding: 3px 20px;
  }

  .item_just_used {
    background-color: #6a5701;
  }

  .item_kategorie {
    background-color: #1b3553;
  }

  .item_berufsberatung {
    background-color: #2d4829;
  }

  .focused {
    background-color: rgb(105, 95, 95);
  }
}

#root_perform_wrapper {
  margin-top: 1em;
  margin-bottom: 1em;
}

.ecedFullMap {
  .level0 {
    margin-top: 20px;
    border-right: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
  }

  .level1 {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;

    >div {
      border-left: 1px solid #CCC;
      border-top: 1px solid #CCC;
      text-align: center;
    }
  }

  .level2 {
    cursor: pointer;

    &.checked {
      background-color: red;
    }
  }

}

.FormSelectCampaignHeader {
  button[type="submit"] {
    display: none;
  }
}

.campaign_details {
  margin-top: 20px;


  .btn-group {
    display: block !important;

    button.array-item-remove {
      width: auto;

      &::before {
        content: "Delete"
      }
    }
  }

  button.btn-add {
    &::before {
      content: "Add"
    }
  }

  fieldset {
    margin-left: 20px;
  }

}

.rjsf_QueryLinkedinProfilesSelectorConfig {

  #root_province,
  #root_source {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  fieldset .form-group {
    margin-bottom: 10px;

    label.control-label {
      font-weight: bold;
    }
  }
}

.pvtOutput {
  tr {
    vertical-align: top;
  }
}